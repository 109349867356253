<template>
  <div class="stationView" style="margin-top:50px">
    <main class="player-content flex-row">
      <section class="flex-1" style="margin: auto 10px;">
        <div class="flex-autorow flex-top flex-stretch player-channel">

  <div class="flex-item flex-1">
    <div class="push-bottom">
      <h4 class="text-nowrap fx fx-slide-left fx-delay-1">TopVote</h4>
      <h5 style="font-size: 0.73rem;" class="text-nowrap fx fx-slide-left fx-delay-1">Découvrez le Top 15 de EnkaRadio</h5>
    </div>
    <div v-if="!hasSongs" class="card push-bottom">
      There are no songs loaded yet for this station.
    </div>
    <ul v-if="info.length" class="player-tracklist push-bottom">

      <li  v-for="( el, i ) of info" :key="el.title"
          :class="'fx-slide-left fx-delay-' + ( i + 2 )"
          class="card fx flex-row flex-top flex-stretch">

        <div class="card_background" :style="'background-image: url( '+el.pochette+' );'"></div>
        <div class="pad-left">
          <div> <span class="text_background text-bright">N° {{ i + 1 }}</span> </div>
          <div> <span style="    text-shadow: 0 0 9px rgba(0, 0, 0, 0.75);" class="text-bright">{{ el.titre }}</span> </div>
          <div><span style="font-size: 13px !important;    text-shadow: 0 0 9px rgba(0, 0, 0, 0.75);" class="text-bright">{{ el.artiste }}</span> </div>
        </div>
      </li>

    </ul>

  </div>

      </div>
      </section>
    </main>

  </div>
</template>

<script>
import axios from 'axios';
import { mapState  } from 'vuex';
export default {
    data () {
    return {
        info: [], // make this an empty array
    }
},
  name: "songsHistory",
  computed: {
    ...mapState('nowplaying',{
      songs : state => state.songs,
    }),
    config(){
      return this.$store.getters["playerConfig/getConfig"];
    },
    hasSongs: (songs) => {
      return (!!Array(songs).length);
    }
  },

  mounted () {
    axios
      .get('https://ythomas.fr/enkaradio/api/topvote.php')
      .then(response => (this.info = response.data))
},
}
</script>

<style scoped>

</style>